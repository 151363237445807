<template>
<v-container fluid fill-height v-if="show">
    <v-card :class="`align-self-stretch ma-2 ` + ($vuetify.breakpoint.mdAndUp ? `pa-4 ` : `pa-0` )" :flat="$vuetify.breakpoint.mdAndUp ? true : false" :elevation="$vuetify.breakpoint.mdAndUp ? `1` : `0`" width="100%">
        <v-card-title class="px-0 justify-space-between">
            <h1>Прайс-листи</h1>
            <v-btn :disabled="loaded" @click="additionalColumnsDialog = !additionalColumnsDialog" class="primary--text">
                <v-icon>settings</v-icon>
                <span class="ml-4" v-if="$vuetify.breakpoint.mdAndUp">Налаштування прайс-листів</span>
            </v-btn>
        </v-card-title>
        <v-card-subtitle class="pa-0 mt-2">
            <h4>Сформуйте необхідний шаблон прайс-листів самостійно!<br>Для цього можна скористатися індивідуальнім налаштуванням колонок та категорій товарів у Налаштуванні прайс-листів (<v-icon>settings</v-icon>).</h4>
        </v-card-subtitle>
        <v-alert type="error" v-if="closed" prominent class="my-4" :width="$vuetify.breakpoint.mdAndUp ? `70%` : `100%`">
            <v-row align="center">
                <v-col class="grow">
                    Немає зв'язку з сервером Прайс-листів.
                </v-col>
                <v-col class="shrink">
                    <v-btn color="white" outlined @click="connectSocket()">Поновити</v-btn>
                </v-col>
            </v-row>
        </v-alert>
        <v-alert type="info" text outlined dense border="left" v-if="selection.length" class="body-2 my-4" :width="$vuetify.breakpoint.mdAndUp ? `70%` : `100%`">Увага! Ви встановили обмеження на категорії Каталога товарів або Тип товару для формування прайс-листів. Перевірте у налаштуваннях.</v-alert>
        <v-row>
            <v-col cols="12" sm="6" md="6" lg="4" class="px-2">
                <v-btn text @click="create('regular','xlsx')" :disabled="loaded || closed" class="px-0 px-sm-2">
                    <span class="pr-4">
                        <v-icon color="primary">{{exclelIcon}}</v-icon>
                    </span>
                    Персональний прайс (Excel)
                </v-btn>
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="8" class="px-2">
                <v-btn text @click="create('regular','xml')" :disabled="loaded || closed" class="px-0 px-sm-2">
                    <span class="pr-4">
                        <v-icon>mdi-xml</v-icon>
                    </span>
                    Персональний прайс (XML) *
                </v-btn>
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="4" class="px-2">
                <v-btn text @click="create('bank','xlsx')" :disabled="loaded || closed" class="px-0 px-sm-2">
                    <span class="pr-4">
                        <v-icon color="primary">{{exclelIcon}}</v-icon>
                    </span>
                    Персональний прайс _Б(Excel)
                </v-btn>
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="8" class="px-2">
                <v-btn text @click="create('bank','xml')" :disabled="loaded || closed" class="px-0 px-sm-2">
                    <span class="pr-4">
                        <v-icon>mdi-xml</v-icon>
                    </span>
                    Персональний прайс _Б(XML) *
                </v-btn>
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="4" class="px-2">
                <v-btn text @click="create('rrc','xlsx')" :disabled="loaded || closed" class="px-0 px-sm-2">
                    <span class="pr-4">
                        <v-icon color="primary">{{exclelIcon}}</v-icon>
                    </span>
                    Контроль РРЦ (Excel)
                </v-btn>
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="8" class="px-2">
                <v-btn text @click="create('rrc','xml')" :disabled="loaded || closed" class="px-0 px-sm-2">
                    <span class="pr-4">
                        <v-icon>mdi-xml</v-icon>
                    </span>
                    Контроль РРЦ (XML) *
                </v-btn>
            </v-col>
        </v-row>
        <v-card-text v-if="loaded || fileBlobURL" class="px-0">
            <!-- <v-progress-linear v-if="loaded" color="primary" class="px-0" indeterminate></v-progress-linear> -->
            <div v-if="loaded">
                <v-progress-circular color="primary" class="mr-4" indeterminate></v-progress-circular>Зачекайте. Файл формується...
            </div>
            <div v-if="fileBlobURL">Готово. Якщо файл не відкрився автоматично, скористайтеся посиланням: <a :href="fileBlobURL" :download="fileName">Відкрити прайс-лист</a></div>
        </v-card-text>
        <!-- <v-list-item class="px-0 mt-4 ml-0 ml-sm-2">
            <v-avatar>
                <v-icon class="pr-6" color="link">{{exclelIcon}}
                </v-icon>
            </v-avatar>
            <span class="ml-n2">Розпродаж уціненої техніки</span>
            <v-btn text class="px-0" target="_blank" href="https://intranet.yugcontract.ua/sm/site/fileslibrary/mis_publ/price/ROZPRODAZH.zip" title="Вивантажити">
                <v-icon>get_app</v-icon>
            </v-btn>
        </v-list-item> -->
        <v-card-subtitle class="mt-4">
            <h4>* Тлумачення тегів XML:</h4>
        </v-card-subtitle>
        <XMLfields />
    </v-card>

    <v-dialog v-model="additionalColumnsDialog" max-width="1200">
        <v-card>
            <v-system-bar window light>
                Налаштування прайс-листа
                <v-spacer></v-spacer>
                <v-btn icon @click="additionalColumnsDialog = false">
                    <v-icon>close</v-icon>
                </v-btn>
            </v-system-bar>
            <v-container>
                <v-row>
                    <v-col>
                        <v-select multiple :items="type_prodItems" label="Оберіть стан товару:" persistent-hint hint="* якщо не обрано жодного значення, то за замовчуванням буде вивантажено товар зі станом Новий, ДУ" v-model="type_prod" @change="saveTypeProd()" clearable></v-select>
                        <v-card flat>
                            <v-card-title>
                                Оберіть асортимент для виведення у прайс:
                            </v-card-title>
                            <v-card-text>
                                <v-treeview :open.sync="opened" @input="saveCat()" v-model="selection" :items="items" selectable dense return-object selected-color="primary"></v-treeview>
                                <!-- <v-divider vertical></v-divider>
                                    <v-col class="pa-6" cols="6">
                                        <v-container style="max-height: 400px" class="overflow-y-auto px-0">
                                            <template v-if="!selection.length">
                                                Налаштування каталогу не визначені
                                            </template>
                                            <template v-else>
                                                <div v-for="node in selection" :key="node.id">
                                                    {{ node.name }}
                                                </div>
                                            </template>
                                        </v-container>
                                    </v-col> -->
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-divider vertical class="my-6"></v-divider>
                    <v-col>
                        <v-card flat>
                            <v-card-title>
                                Оберіть додаткові дані для виведення у прайс:
                            </v-card-title>
                            <v-card-text>
                                <v-card class="d-flex flex-wrap ml-xs-6 ml-sm-10 mt-5" flat tile>
                                    <v-card v-for="(item, index) in columns" :key="index" class="pa-0" flat tile :width="$vuetify.breakpoint.smAndUp ? `50%` : `100%`">
                                        <v-switch dense v-model="columns[index].selected" color="primary" @change="saveCols()" :label="item.name"></v-switch>
                                    </v-card>
                                </v-card>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="additionalColumnsDialog = false" color="primary">Застосувати</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</v-container>
</template>

<script>
import XMLfields from './XMLFields.vue'

import {
    mapActions,
    mapGetters
} from 'vuex'

import {
    mdiMicrosoftExcel

} from '@mdi/js'

import {
    connect
} from "../Socket"

export default {
    components: {
        XMLfields
    },
    directives: {},
    data: () => ({
        show: false,
        opened: [{
            id: 0
        }],
        additionalColumnsDialog: false,
        exclelIcon: mdiMicrosoftExcel,
        fileBlobURL: '',
        type_prod: [],
        closed: false,
        loaded: false,
        fileName: '',
        mime: {
            xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
            xml: "application/xml"
        },
        format: '',
        se: {},
        socket: {},
        selection: [],
        items: [],
        columns: [{
                name: 'Стан (опис Уцінки)',
                col: 'type',
            },
            {
                name: 'Артикул',
                col: 'artikul',
            },
            {
                name: 'Штрих-код',
                col: 'barcode'
            },
            {
                name: 'Гарантійний термін',
                col: 'warranty'
            },
            {
                name: 'Країна',
                col: 'country'
            },
            {
                name: 'РРЦ (без Промо), грн.',
                col: 'price_rrc_without_promo'
            },
            {
                name: 'Ціна без ПДВ, грн.',
                col: 'price_wout_vat'
            },
            {
                name: 'Ставка ПДВ',
                col: 'vat'
            },
            {
                name: 'Код УКТ',
                col: 'fea_code'
            },
            {
                name: 'У пакуванні, шт.',
                col: 'pack'
            },
            {
                name: 'У коробці, шт.',
                col: 'pack_box'
            },
            {
                name: 'У палеті, шт.',
                col: ' pack_pallet'
            },
            {
                name: 'Вага, кг',
                col: 'weight'
            },
            {
                name: 'Об’єм, м3',
                col: 'volume'
            },
            {
                name: 'Серія',
                col: 'line'
            },
            {
                name: 'Опис скорочений',
                col: 'descr_typhoon'
            },
            {
                name: 'Опис повний',
                col: 'descr_url'
            },
            {
                name: 'Фото',
                col: 'bigimg'
            },
            {
                name: 'Фото (велике)',
                col: 'origin_img_url',
            },
            {
                name: 'Ексклюзив ЮК',
                col: 'is_exclusive'
            },
        ],
        type_prodItems: [{
                text: 'Новий',
                value: 'new'
            },
            {
                text: 'Дефект упаковки (ДУ)',
                value: 'du'
            },
            {
                text: 'Уцінка',
                value: 'priceoff'
            },
        ],

    }),
    props: {},
    methods: {
        ...mapActions(['setSnackbar', 'touch', 'getCatalogTree', 'getContactInfo']),
        ...mapGetters(['catalogTree']),
        create(type, format) {
            this.touch()
                .then(() => {
                    window.URL.revokeObjectURL(this.fileBlobURL); //очистить память от ранее принятого файла
                    this.fileBlobURL = ''
                    this.loaded = true
                    this.format = format
                    const extendedCols = this.getLocalStorage('priceAddCols') || []
                    const cats = this.selection.map(el => el.id) || []
                    const user_id = this.contactInfo.userId
                    const type_prod = this.type_prod
                    this.se.send(JSON.stringify({
                        user_id,
                        type,
                        type_prod,
                        format,
                        extendedCols,
                        cats,
                        origin: window.location.origin,
                        authToken: this.getLocalStorage('authToken')
                    }))
                })
        },
        saveData(data, fileName, format) {
            const a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            const blob = new Blob([data], {
                type: this.mime[this.format]
            })
            let url = window.URL.createObjectURL(blob);
            this.fileBlobURL = a.href = url;
            a.download = fileName;
            a.click();
        },
        getLocalStorage(f) {
            let value = ''
            try {
                value = localStorage[f] ? JSON.parse(localStorage[f]) : ''
            } catch (error) {
                console.error(error)
                return ''
            }
            return value
        },
        connectSocket() {
            const {
                se,
                socket
            } = connect()
            this.loaded = false
            this.se = se
            this.socket = socket

            this.se.$on("message", (msg) => {
                const date = new Date().toISOString()
                const filename = `${date}_price-list.${this.format}`
                this.fileName = filename
                this.saveData(msg, filename);
                this.loaded = false
            })
            this.se.$on("closed", () => {
                setTimeout(() => {
                    this.closed = true
                    console.log("Websocket closed")
                }, 5000);
            })
            this.se.$on("error", (error) => {
                this.setSnackbar(['error', "Не вдалося встановити з'єднання з сервером"])
            })
            this.se.$on("opened", () => {
                this.closed = false
            })
        },
        saveCols() {
            this.setLocalStorage('priceAddCols', this.columns.filter(el => el.selected == true).map(el => el.col))
        },
        getLocalStorage(f) {
            return localStorage[f] ? JSON.parse(localStorage[f]) : undefined
        },
        setLocalStorage(f, v) {
            localStorage[f] = JSON.stringify(v)
        },
        saveCat() {
            this.setLocalStorage('selectedCats', this.selection)
        },
        saveTypeProd() {
            this.setLocalStorage('typeProd', this.type_prod)
        },
        clearCache() {
            this.touch()
                .then(() => {
                    window.URL.revokeObjectURL(this.fileBlobURL); //очистить память от ранее принятого файла
                    this.fileBlobURL = ''
                    this.se.send(JSON.stringify({
                        clearCache: true,
                        authToken: this.getLocalStorage('authToken')
                    }))
                })
        },
        setOpened() {
            this.opened.push()
        }
    },
    computed: {
        contactInfo() {
            return this.$store.state.main.contactInfo
        },
    },
    created() {
        this.getContactInfo()
            .then(data => {
                if (!data.isSellToEmployee) {
                    this.show = true
                } else {
                    this.$router.push('/403')
                }
            })
            .catch(error => console.error(error))

        this.touch()
            .then(() => {
                this.selection = this.getLocalStorage('selectedCats') || []
                this.type_prod = this.getLocalStorage('typeProd')
                if (!Array.isArray(this.type_prod)) {
                    this.type_prod = this.type_prod ? [this.type_prod] : []
                }
                this.saveTypeProd()

                this.connectSocket()
                this.getCatalogTree()
                    .then(data => {
                        this.items.push({
                            id: 0,
                            name: 'Виберіть категорію товарів:',
                            children: []
                        })
                        for (let idx in data.menuItems) {
                            const item = data.menuItems[idx]
                            this.items[0].children.push({
                                id: item.link,
                                name: item.text,
                                children: []
                            })
                            for (let i in data.menuSubItems) {
                                if (i == item.link) {
                                    const elements = data.menuSubItems[i]
                                    for (let id in elements) {
                                        const el = elements[id]
                                        this.items[0].children[idx].children.push({
                                            id: el.link,
                                            name: el.text,
                                            children: []
                                        })
                                        if (data.menuSubItems[el.link]) {
                                            const elements = data.menuSubItems[el.link]
                                            for (let el of elements) {
                                                this.items[0].children[idx].children[id].children.push({
                                                    id: el.link,
                                                    name: el.text,
                                                    children: []
                                                })
                                            }
                                        }

                                    }
                                }
                            }
                        }
                    })
            })
        const local = this.getLocalStorage('priceAddCols') || []
        for (let i in this.columns) {
            if (local.find(el => this.columns[i].col == el)) {
                this.columns[i].selected = true
            } else {
                this.columns[i].selected = false
            }
            this.$set(this.columns, i, this.columns[i])
        }
    }
}
</script>

<style>
</style>
